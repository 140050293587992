import type { FortePage } from "./_app";

/**
 * We need this page because we need an index,
 * but MaybeViewerContext and AuthGatekeeper will handle redirects before you get here
 */
export const Index: FortePage = () => null;

Index.pageTitle = "Welcome";

export default Index;
